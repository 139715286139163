<template>
  <div>
    <NavTitle class="mb16" title="注册统计" :showBack="false"></NavTitle>
    <div class="panel mt16">
      <el-form size="small">
        <el-form-item label="应用渠道">
          <el-select class="mr10" v-model="form.ch" placeholder="请选择渠道">
            <el-option
              v-for="item in channelList"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间范围">
          <el-date-picker
            class="mr10"
            v-model="form.screenDate"
            value-format="yyyyMMdd"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" :loading="isGetting" @click="getUrl">导出</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import { mapState } from 'vuex'
import { chRegisterUsersApi } from '@/api/state.js'
import { dictListApi } from '@/api/opera.js'
export default {
  name: 'RegisterStatistic',
  components: {
    NavTitle
  },
  data() {
    return {
      channelList: [], // {key:'wx_soul', value: 'wx_soul'}
      form: {
        ch: '',
        screenDate: ''
      },
      downloadUrl: '',
      isGetting: false
    }
  },
  computed: {
    ...mapState(['authChs'])
  },
  created() {
    this.getChannelList()
  },
  methods: {
    getChannelList() {
      console.log('授权渠道', this.authChs)
      if(!this.authChs || this.authChs.length == 0) return
      dictListApi({
        code: 'channel',
        pageSize: 20,
        startPage: 0
      }).then(res => {
        console.log('渠道列表', res)
        const list = res.data?.dataList[0]?.dictItem || []
        for (const item of list) {
          const index = this.authChs.findIndex(ch => ch == item.key)
          if(index !== -1) this.channelList.push(item)
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getUrl() {
      if(this.isGetting) return
      if(!this.form.ch) return this.$tips({message: '请选择渠道', type: 'warning'})
      const formData = {
        ch: this.form.ch
      }
      if(this.form.screenDate?.length > 0) {
        Object.assign(formData, {
          startDate: this.form.screenDate[0],
          endDate: this.form.screenDate[1]
        })
      }
      console.log('formData', formData)
      this.isGetting = true
      chRegisterUsersApi(formData).then(res => {
        console.log('用户注册', res)
        if(res.data) window.open(res.data, '_blank')
        this.isGetting = false
      }).catch(err => {
        this.isGetting = false
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>